import React, { useRef} from "react";
import styled from 'styled-components';

const YoutubeWrapper = styled.div`
    /* cursor: pointer; */

  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 1px;
  min-width: 100%;

  iframe {
    height: 100%;
    width: 1px;
    min-width: 100%;
    position: absolute;
  }
`

const Youtube = ({ id, url }) => {
  const videoDiv = useRef(null);


  return (
      <YoutubeWrapper ref={videoDiv}>
          <iframe 
              id="teste"
              loading="lazy"
              title="VSL"
              className="spotlightr" 
              allowtransparency="true"
              allowFullScreen={true} 
              src="https://player.vimeo.com/video/695307536?h=e2724ae283"
              // https://player.vimeo.com/video/694284120?h=3c028d557a"
              // "https://player.vimeo.com/video/689809298?h=c45e926222"
              // src="https://player.vimeo.com/video/674013929?h=679be52117" 
              frameBorder="0" 
              scrolling="no"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              name="videoPlayerframe"> 
          </iframe>  
      </YoutubeWrapper>
  )
}

export default Youtube;

  /* overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 1px;
  min-width: 100%;

  iframe {
    height: 100%;
    width: 1px;
    min-width: 100%;
    position: absolute;
  } */

          {/* <iframe 
              title="VSL"
              className="spotlightr" 
              allowtransparency="true"
              allowFullScreen={true} 
              src="https://videomng.builderall.com/embed/5PAv0rILo0/?autoplay=1&countdown=1&controls=0&allowpause=1" 
              frameBorder="0" 
              scrolling="no"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              name="videoPlayerframe"> 
          // </iframe> */}

          {/* <Helmet>
            <script type="text/javascript" id="scr_61ef751bc48aef00081ccd3b">{`
            { 
              var s=document.createElement("script");
              s.src="https://cdn.converteai.net/92fcdc7e-6465-4fcd-925a-a8ec12601466/players/61ef751bc48aef00081ccd3b/player.js",
              s.async=true,
              document.head.appendChild(s);
            }
            `}</script>
          </Helmet> */}